<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
    <v-layout wrap px-5 pt-5>
      <v-flex xs12 sm9 md10 text-left>
        <span style="color: #000; font-family: poppinsbold; font-size: 25px"
          >Purchase List</span
        >
      </v-flex>
      <v-flex xs12 sm3 md2>
        <v-select
          dense
          :items="items"
          v-model="orderStatus"
          label="Order Status"
          outlined
          item-text="name"
          item-value="value"
        ></v-select>
      </v-flex>
    </v-layout>
    <v-layout wrap pt-5 px-5>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="purchase"
          :items-per-page="limit"
          hide-default-footer
          class="elevation-1 .text--black"
          id="virtual-scroll-table"
        >
          <template v-slot:[`item.create_date`]="{ item }">
            <span v-if="item.create_date">{{
              item.create_date.slice(0, 10)
            }}</span>
          </template>
          <template v-slot:[`item._id`]="{ item }">
            <v-icon small class="mr-2" @click="info(item)"> mdi-eye </v-icon>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-2>
      <v-flex xs12>
        <div class="text-center pb-5" v-if="pages > 1">
          <v-pagination
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="green"
            circle
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      msg: null,
      timeout: 5000,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 20,
      purchase: [],
      orderStatus: "",
      items: [
        { name: "All", value: "" },
        { name: "Active", value: "Active" },
        { name: "Accepted", value: "Accepted" },
        { name: "Shipped", value: "Shipped" },
        { name: "Cancelled", value: "Cancelled" },
        { name: "Delivered", value: "Delivered" },
      ],
      userType: "",
      keyword: "",
      headers: [
        { text: "Name", value: "userId.name", width: "140px" },
        { text: "Email", value: "userId.email", width: "100px" },
        { text: "Phone", value: "userId.phone", width: "80px" },
        { text: "Amount", value: "subTotal", width: "80px" },
        { text: "Transaction Id", value: "orderId", width: "80px" },
        { text: "Date", value: "create_date", width: "80px" },
        { text: "Actions", value: "_id", width: "50px" },
      ],
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
    orderStatus() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/admin/seller/purchaseList",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          limit: this.limit,
          page: this.currentPage,
          orderStatus: this.orderStatus,
          sellerId: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.purchase = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    info(id) {
      this.$router.push("/purchaseDetails?id=" + id._id);
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>
  <style>
.v-data-table-header {
  background-color: #cccac6;
  font-size: 20px;
  color: white;
}

.v-data-table .v-data-table-header tr th {
  font-size: 15px !important;
  color: black;
  font-family: poppinsmedium;
}

.v-data-table tr td {
  font-size: 13px !important;
  color: black;
  font-family: poppinsmedium;
}

#virtual-scroll-table {
  max-width: 1200px;
  overflow: auto;
}
</style>